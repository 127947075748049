<script>
import MainTitleBack from "@/components/helpers/MainTitleBack.vue";
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  components: {
    MainTitleBack,
    MainButton,
  },
};
</script>

<template>
  <div class="self-employed default-page-wrapper">
    <main-title-back
      title="Регистрация на сервисе партнёра Консоль"
      href="BalanceOutput"
    />

    <div class="self-employed__wrapper">
      <div>
        <p>Для регистрации в Консоли, необходимо перейти по ссылке:</p>
        <p>
          <a href="https://app.konsol.pro/join/rk-tech_smz">
            https://app.konsol.pro/join/rk-tech_smz
          </a>
          - для СЗ
        </p>
        <p>
          <a href="https://app.konsol.pro/join/it_technologies_ip">
            https://app.konsol.pro/join/it_technologies_ip
          </a>
          - для ИП
        </p>
      </div>

      <p>
        Для получения инструкции перейдите по ссылке:<br />
        <a href="https://help.konsol.pro/registration-smz" target="_blank">
          https://help.konsol.pro/registration-smz
        </a>
      </p>

      <p>
        Для регистрации в приложении перейдите по ссылке:<br />
        <a
          href="https://konsolpro.notion.site/5b8fe9e7510b41c8b95d058275a9cc7a"
          target="_blank"
        >
          https://konsolpro.notion.site/5b8fe9e7510b41c8b95d058275a9cc7a
        </a>
      </p>

      <p>*Регистрация по данной ссылке возможна с любого устройства</p>

      <p>
        ВАЖНО! <br />
        В приложении "Консоль" подключите функцию "Налоговая копилка." Не
        забудьте предоставить сервису "Консоль" уникальные права на уплату
        отчислений от вашего имени. Сделать это можно в приложении "Мой налог",
        в разделе "Партнеры", во вкладке "Прочее".
      </p>

      <main-button
        color="dark"
        title="Готово"
        @click="$router.push({ name: 'BalanceOutput' })"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.self-employed {
  background-color: $background-white;
  padding-top: $space-xxl;

  &__wrapper {
    background-color: $light-second;
    border-radius: $space-s;
    padding: $space-xl;
    max-width: 694px;

    > div {
      > p {
        margin-bottom: 0;

        &:last-child {
          margin-bottom: 24px;
        }
      }
    }

    p {
      margin-bottom: $space-l;
      @include body-1;
    }

    a {
      text-decoration: none;
      color: $blue;
    }

    .main-button {
      max-width: 96px;
    }
  }
}
</style>
